import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCashbookList() {
  // Use toast
  const toast = useToast()

  const refCashbookListTable = ref(null)

  const perPage = ref(1000)
  const totalCashbooks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100, 250, 500, 1000, 2000]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const ledgerFilter = ref(null)
  const branchFilter = ref(null)
  const typeFilter = ref(null)
  const dateFilterValue = ref(null)

  const current = new Date()
  const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
  const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
  const today = `${current.getFullYear()}-${month}-${date}`
  dateFilterValue.value = today

  const dataMeta = computed(() => {
    const localItemsCount = refCashbookListTable.value ? refCashbookListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCashbooks.value,
    }
  })

  const refetchData = () => {
    refCashbookListTable.value.refresh()
  }

  watch([currentPage, perPage, ledgerFilter, branchFilter, typeFilter, dateFilterValue], () => {
    refetchData()
  })

  const cashIn = ref(null)
  const cashOut = ref(null)
  const cashInTotal = ref(0)
  const cashOutTotal = ref(0)
  const cashInOutBalance = ref(0)

  const fetchCashbooks = (ctx, callback) => {
    store
      .dispatch('cashbook/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        ledger_id: ledgerFilter.value,
        branch_id: branchFilter.value,
        date_range: dateFilterValue.value,
        type: typeFilter.value,
      })
      .then(response => {
        const { cashbooks, total } = response.data.data

        callback(cashbooks)
        const filteredCashIn = cashbooks.filter(cash => cash.type === 'cash_in')
        const filteredCashOut = cashbooks.filter(cash => cash.type === 'cash_out')

        cashInTotal.value = filteredCashIn.reduce((tt, obj) => Number(obj.amount) + tt, 0)
        cashOutTotal.value = filteredCashOut.reduce((tt, obj) => Number(obj.amount) + tt, 0)
        cashInOutBalance.value = cashInTotal.value - cashOutTotal.value

        cashIn.value = filteredCashIn
        cashOut.value = filteredCashOut

        totalCashbooks.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting cashbook list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchCashbooks,
    cashIn,
    cashOut,
    cashInOutBalance,
    cashInTotal,
    cashOutTotal,
    perPage,
    currentPage,
    totalCashbooks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCashbookListTable,

    refetchData,

    // Filters
    ledgerFilter,
    branchFilter,
    dateFilterValue,
    typeFilter,
  }
}
